import Home from "./pages/Home.js";



const page = () => {
	if (document.querySelector("#Home")) return Home;
};

if (!page()) return
const app = Vue.createApp(page());

app.config.globalProperties.$console = console.log
app.mount("#Home");


