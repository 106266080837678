const { ref } = Vue

const categorias = ref([])
const atuacoes = ref([])
const ativos = ref([])

const loadingAccordion = ref(false)
const detalhesCidade = ref([])
const { objToFilterString } = GLOBAL

const addActiveFalse = (i) => ({ ...i, active: false })
const addUniqueID = (i) => ({ ...i, uniqueID: crypto.randomUUID() })

async function getCategorias(filtro) {
	try {
		const { data } = await api.get(URLS.categorias + objToFilterString(filtro))
		categorias.value = data.map(addActiveFalse)
		return data.map(addActiveFalse)
	} catch (error) {
		console.error('ERRO: ', error.message)
		return error
	}
}

let controller

async function getAtuacoes(filtro) {
	loadingAccordion.value = true
	atuacoes.value = []

	if (controller?.signal && !controller.signal.aborted) {
		controller.abort()
	}

	controller = new AbortController()

	try {
		const { data } = await api.get(URLS.mapa + objToFilterString(filtro), {
			signal: controller.signal,
		})

		atuacoes.value = data.map((i) => {
			i.categorias = i.categorias.map(addUniqueID).map(addActiveFalse)
			return i
		})

		return atuacoes.value
	} catch (error) {
		console.error('ERRO: ', error.message)
		return error
	}
}

async function getDetalhes(id, filtro) {
	try {
		const { data } = await api.get(URLS.mapa_detalhes + id + objToFilterString(filtro))
		detalhesCidade.value = data
		return data
	} catch (error) {
		console.error('ERRO: ', error.message)
		return error
	}
}

export {
	getCategorias,
	getAtuacoes,
	categorias,
	atuacoes,
	ativos,
	loadingAccordion,
	getDetalhes,
	detalhesCidade,
}
