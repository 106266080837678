import MapaAtuacao from '../components/MapaAtuacao.js'
import AccordionWrapper from '../components/AccordionWrapper.js'
import CategoriasMapa from '../components/CategoriasMapa.js'
import ModalAtuacao from '../components/ModalAtuacao.js'
import ModalBase from '../components/ModalBase.js'
import VideoBase from '../components/VideoBase.js'
import { state, open, close } from '../store/modalAtuacao.store.js'
import {
	state as stateModalBasic,
	open as openModalBasic,
	close as closeModalBasic,
} from '../store/modalBasic.store'

import {
	getCategorias,
	getAtuacoes,
	categorias,
	atuacoes,
	ativos,
	loadingAccordion,
} from '../store/mapaAtuacao.store.js'

import { deepUnref } from 'vue-deepunref'

const { ref, computed, onMounted, watch } = Vue

export default {
	components: {
		MapaAtuacao,
		AccordionWrapper,
		CategoriasMapa,
		ModalAtuacao,
		ModalBase,
		VideoBase,
	},

	setup() {
		const inicial = ref(true)
		const imageActive = ref(null)

		const cidadeActive = ref({ id: null })

		function handleClickPin(uniqueID) {
			console.log(uniqueID)

			atuacoes.value = atuacoes.value.map((atuacao) => {
				atuacao.categorias.forEach((c) => {
					if (c.uniqueID === uniqueID) {
						c.active = true
						cidadeActive.value.id = atuacao.id
						console.warn(atuacao.id)
					} else c.active = false
				})

				return atuacao
			})
		}

		const refModalAtuacao = ref(null)

		const categoriasAtivas = computed(() =>
			categorias.value.filter((categoria) => categoria.active)
		)

		const haveMundo = computed(() =>
			categoriasAtivas.value.find((c) => c.nome === 'Mundo')
		)

		function getAtivos(arr) {
			return deepUnref(arr).reduce((acc, i) => {
				i.categorias.forEach((categoria) => {
					const ativos = categoria.ativos.map((i) => {
						i.categoriaUniqueID = categoria.uniqueID
						return i
					})
					acc.push(...ativos)
				})
				return acc
			}, [])
		}

		async function repopulateData() {
			atuacoes.value = []
			ativos.value = []

			if (categoriasAtivas.value.filter((i) => i.active)?.length) {
				inicial.value = false
			}

			try {
				await getAtuacoes({
					categoria: categoriasAtivas.value.map(({ id }) => id).join(','),
				})
				ativos.value = getAtivos(atuacoes)
			} catch (e) {
				console.log('erro')
			}
		}

		function openModal(cidadeID) {
			// const cidade = atuacoes.value.reduce((acc, i) => {
			// 	if (i.id === cidadeID) {
			// 		acc = i
			// 	}
			// 	return acc
			// })

			open(cidadeID)
		}

		function handleClickClear() {
			cidadeActive.value = { id: null }
			inicial.value = true
		}

		function handleClickCategorias(v) {
			console.log('handleClickCategorias')
			categorias.value = v
		}

		watch(
			() => categoriasAtivas,
			async () => {
				console.log('mudou categoria')
				await repopulateData()
			},

			{ deep: true }
		)

		watch(
			() => atuacoes,
			async (v) => {
				if (v.value.length) {
					loadingAccordion.value = false
				}
			},

			{ deep: true, immediate: true }
		)

		onMounted(() => {
			getCategorias()
			// getAtuacoes(categoriasAtivas.value);
		})

		return {
			categorias,
			handleClickPin,
			refModalAtuacao,
			state,
			open,
			close,
			atuacoes,
			ativos,
			inicial,
			openModal,
			cidadeActive,
			handleClickClear,
			handleClickCategorias,
			loadingAccordion,
			imageActive,
			openModalBasic,
			haveMundo,
		}
	},

	delimiters: ['{*', '*}'], //delimitadores pra n conflitar com o django
}
