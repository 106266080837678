const { ref, watch } = Vue

export default {
	emits: ['click:categoria', 'click:clear'],

	props: {
		categorias: Array,
		noclose: {
			type: Boolean,
			default: false,
		},
	},

	setup(props, ctx) {
		function changeActive(id) {
			if (!id) {
				ctx.emit(
					'click:categoria',
					props.categorias?.map((i) => {
						i.active = false
						return i
					})
				)
				ctx.emit('click:clear')
			} else {
				ctx.emit(
					'click:categoria',
					props.categorias?.map((i) => {
						if (i.id === id) i.active = !i.active
						return i
					})
				)
			}
		}
		return {
			changeActive,
		}
	},

	template: html`
		<ul class="mapa--list">
			<li
				v-for="item in categorias"
				:class="{'active': item.active}"
				@click.stop.prevent="changeActive(item.id)"
				:style="{ background: item.cor }"
			>
				<span>{{item.nome}}</span>
			</li>
			<li
				v-if="categorias.filter(i => i.active)?.length && !noclose"
				class="clear"
				@click="changeActive(false)"
			>
				<svg>
					<use xlink:href="#icon_close"></use>
				</svg>
			</li>
		</ul>
	`,
}
