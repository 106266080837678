const { ref } = Vue

const state = ref({
	open: false,
	id: null,
})

function open(id) {
	state.value = { open: true, id }
}

function close() {
	state.value = { open: false, id: null }
}

export { state, open, close }
