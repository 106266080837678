import AccordionItem from './AccordionItem.js'

const { ref, watch } = Vue

export default {
	emits: ['click:header', 'click:button'],

	components: { AccordionItem },

	props: {
		items: Array,
		single: {
			type: Boolean,
			default: false,
		},
		inicial: Boolean,
		cidadeActive: [Number, Object, NaN],
		loading: Boolean,
	},

	setup(props, ctx) {
		const cidadeActive = ref({ id: null })
		const refAccordionItem = ref(null)

		watch(
			() => props.cidadeActive,
			(v) => {
				console.log('cidadeActive.value.id', v)
				cidadeActive.value.id = v.id
			},
			{ deep: true }
		)

		function handleChange(id) {
			if (props.single) {
				ctx.emit(
					'click:header',
					props.items?.map((i) => {
						i.categorias.forEach((c) => {
							if (c.uniqueID !== id) {
								c.active = false
							}
							return c
						})
						return i
					})
				)
			}
			ctx.emit(
				'click:header',
				props.items?.map((i) => {
					i.categorias.forEach((c) => {
						console.log(c.active, c.uniqueID === id)
						if (c.uniqueID === id) c.active = !c.active
						return c
					})
					return i
				})
			)
		}

		function setScroll() {
			const item = refAccordionItem.value.reduce((acc, i) => {
				if (i.dataset?.id == cidadeActive.value.id) {
					acc = i
				}
				return acc
			})
			console.log(item, 'item => scrollIntoViewIfNeeded')
			item?.scrollIntoViewIfNeeded()
		}

		const heightItem = () => {
			setScroll()
			return '100%'
		}

		watch(
			() => props.inicial,
			(v) => {
				if (!v) {
					cidadeActive.value.id = null
				}
			},
			{ deep: true }
		)

		watch(
			() => cidadeActive,
			(v) => {
				if (v.value.id) {
					setScroll()
				}
			},
			{ deep: true }
		)

		const cidadeisEqual = (id) => cidadeActive.value.id === id

		function haveAtivos(arr) {
			return arr.reduce((acc, i) => {
				if (i.ativos.length) acc.push(true)
				return acc
			}, []).length
		}

		function handleClickAccordion(id) {
			cidadeActive.value.id = id
		}

		return {
			handleChange,
			cidadeActive,
			refAccordionItem,
			heightItem,
			cidadeisEqual,
			haveAtivos,
			handleClickAccordion,
		}
	},

	template: html`
		<div class="accordion--wrapper" v-if="!loading">
			<template v-for="cidade in items" :key="cidade.id">
				<div v-if="haveAtivos(cidade.categorias)">
					<!-- NOME CIDADE -->
					<h5 @click="handleClickAccordion(cidade.id)" class="city-name">
						<div class="city-name-wrapper">
							{{cidade.nome}}

							<button
								v-if="inicial && cidadeisEqual(cidade.id)"
								class="ver-detalhes"
								@click="$emit('click:button', cidade.id)"
							>
								Ver Ativos
							</button>
						</div>
						<span v-if="cidadeActive.id !== cidade.id && inicial">
							<svg>
								<use xlink:href="#icon_close"></use>
							</svg>
						</span>
					</h5>
					<!-- Categorias -->
					<div
						:style='{height: inicial ?  cidadeisEqual(cidade.id) ? heightItem() : "0" : "100%"}'
						class="city-wrapper"
						ref="refAccordionItem"
						:data-id="cidade.id"
					>
						<div
							v-for="categoria in cidade.categorias"
							:key="categoria.uniqueID"
						>
							<AccordionItem
								v-if="categoria.ativos.length"
								:titulo="categoria.nome"
								:data="categoria"
								:cidadeID="cidade.id"
								:inicial="inicial"
								:showBtn="cidadeisEqual(cidade.id)"
								@click:header="handleChange(categoria.uniqueID)"
								@click:button="$emit('click:button', cidade.id)"
							>
							<!-- ATIVOS -->
							
							<div class="ativo-wrapper" v-for="ativo in categoria.ativos">
								<strong class="categoria-legenda">{{ativo.legenda}}</strong>
								<!-- 	<h4>{{ativo.nome}}</h4> -->
									<ul>
										<li v-for="descricao in ativo.descricao">
											{{descricao.content}}
										</li>
									</ul>
								</div>
							</AccordionItem>
						</div>
					</div>
				</div>
			</template>
		</div>
		<div v-else class="loader-container">
			<span class="loader-basic"></span>
		</div>
	`,
}
