const { computed } = Vue

export default {
	props: {
		icone: { type: String, default: 'aviao' },
		ativo: Object,
	},

	setup(props) {
		return {
			x: computed(() => props.ativo.pin.position.x),
			y: computed(() => props.ativo.pin.position.y),
		}
	},
	
	template: html`<span
		v-once
		class="icone_mapa"
		:style="{ 'left': ativo.pin.position.x , 'top': ativo.pin.position.y, color: ativo.cor }"
	>
		<span class="name">
			<svg>
				<use :xlink:href="'#icon_' + icone"></use>
			</svg>
			{{ ativo.nome }}
		</span>
		<svg>
			<use :xlink:href="'#icon_' + icone"></use>
		</svg>
	</span>`,
}
