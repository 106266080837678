export default {
	setup() {
		return {
			labels: [
				{
					x: '525px',
					y: '188px',
					name: 'Pernambuco',
				},
				{
					x: '531px',
					y: '168px',
					name: 'Paraíba',
				},
				{
					x: '523px',
					y: '151px',
					name: 'Rio Grande do Norte',
				},
				{
					x: '512px',
					y: '209px',
					name: 'Alagoas',
				},
				{
					x: '495px',
					y: '225px',
					name: 'Sergipe',
				},
				{
					x: '433px',
					y: '242px',
					name: 'Bahia',
				},
				{
					x: '395px',
					y: '331px',
					name: 'Minas Gerais',
				},
				{
					x: '451px',
					y: '361px',
					name: 'Espírito Santo',
				},
				{
					x: '427px',
					y: '391px',
					name: 'Rio de Janeiro',
				},
				{
					x: '352px',
					y: '407px',
					name: 'São Paulo',
				},
				{
					x: '289px',
					y: '424px',
					name: 'Paraná',
				},
				{
					x: '328px',
					y: '455px',
					name: 'Santa Catarina',
				},
				{
					x: '279px',
					y: '485px',
					name: 'Rio Grande do Sul',
				},
				{
					x: '261px',
					y: '365px',
					name: 'Mato Grosso do Sul',
				},
				{
					x: '329px',
					y: '301px',
					name: 'Goiás',
				},
				{
					x: '264px',
					y: '252px',
					name: 'Mato Grosso',
				},
				{
					x: '145px',
					y: '230px',
					name: 'Rondônia',
				},
				{
					x: '53px',
					y: '205px',
					name: 'Acre',
				},
				{
					x: '125px',
					y: '130px',
					name: 'Amazonas',
				},
				{
					x: '270px',
					y: '144px',
					name: 'Pará',
				},
				{
					x: '346px',
					y: '223px',
					name: 'Tocantins',
				},
				{
					x: '387px',
					y: '146px',
					name: 'Maranhão',
				},
				{
					x: '414px',
					y: '186px',
					name: 'Piauí',
				},
				{
					x: '463px',
					y: '139px',
					name: 'Ceará',
				},
				{
					x: '299px',
					y: '47px',
					name: 'Amapá',
				},
				{
					x: '172px',
					y: '41px',
					name: 'Roraima',
				},
			],
		}
	},

	template: html`
		<div
			class="mapa-labels"
			v-for="label in labels"
			:style="{ left:label.x, top:label.y}"
			>
			{{label.name}}

		</div>
	`,
}
