const { ref } = Vue

export default {
	emits: [],

	props: {
    videoUrl: String
  },

	setup(props, ctx) {},

	template: html`
   <video :src="videoUrl" controls class="w-full" style="width: 100%"></video>
  `,
}
