/* {% static 'front_assets/src/assets/icons/arrow-down.svg' %} */

const { ref, computed } = Vue

export default {
	emits: ['click:header', 'click:button'],

	props: {
		data: Object,
		titulo: String,
		cidadeID: Number,
		inicial: Boolean,
		showBtn: Boolean,
	},

	setup(props) {
		const refContent = ref(null)

		const heightContent = computed(() => refContent.value?.scrollHeight + 'px')

		const style = computed(() => ({
			'--height': props.data.active ? heightContent.value : 0 + 'px',
		}))

		return {
			refContent,
			style,
		}
	},

	template: html`
		<li class="accordion--item" :class="{ 'active' :  data.active }">
			<h5 @click="$emit('click:header')">
				{{titulo}}
				<br>
		
				<div class="flex-1"></div>
				<button
					v-show="!inicial &&  data.active"
					class="ver-detalhes"
					@click="$emit('click:button', cidadeID)"
				>
					Ver Ativos
				</button>
				<svg>
					<use xlink:href="#arrow_down"></use>
				</svg>
			</h5>

			<div class="accordion--content" :style="style">
				<div class="accordion--text active--accordion" ref="refContent">
					<slot></slot>
				</div>
			</div>
		</li>
	`,
}

/* 

		

*/
