import { state, open, close } from '../store/modalBasic.store'

const { ref, watch } = Vue

export default {
	emits: [],

	props: {
		nome: String,
	},

	setup(props, ctx) {
		watch(
			() => state.value.open,
			(v) => {
				if (v) document.documentElement.classList.add('modal-open')
				else document.documentElement.classList.remove('modal-open')
			}
		)

		return { state, open, close }
	},

	template: html`<div class="modal-basic" :class="{'active': state.open }">
		<div class="modal--background" @click.stop.prevent.self="close">
			<div class="modal--container">
				<div class="modal--heading modl-bs">
					<h4>{{nome}}</h4>
					<button @click.stop.prevent="close">
						<svg>
							<use xlink:href="#icon_close"></use>
						</svg>
					</button>
				</div>

				<div class="modal--body">
					<slot></slot>
				</div>
			</div>
		</div>
	</div> `,
}
