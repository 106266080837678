const { ref } = Vue

const state = ref({
	open: false,
	cidadeID: null,
})

function open(cidadeID) {
	state.value = {
		open: true,
		cidadeID,
	}
}

function close() {
	console.log('close')
	state.value = {
		open: false,
		cidadeID: null,
	}
}

function toogle() {
	// console.log('toogle')
	// state.value.open = !state.value.open
}

export { state, open, close, toogle }
