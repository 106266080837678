export default {
	name: 'SimpleGallery',
	props: {
		galleryID: String,
		images: Array,
	},
	setup(props) {
		const imagesData = Vue.ref(props.images)
		Vue.watch(
			() => props.images,
			(v) => {
				imagesData.value = v
			},
			{ deep: true }
		)
		return {
			imagesData,
		}
	},

	mounted() {
		if (!this.lightbox) {
			this.lightbox = new PhotoSwipeLightbox({
				gallery: '#' + this.$props.galleryID,
				children: 'a',
				pswpModule: () => import('photoswipe'),
			})
			this.lightbox.init()
		}
	},
	unmounted() {
		if (this.lightbox) {
			this.lightbox.destroy()
			this.lightbox = null
		}
	},
	template: html`<div
		:id="galleryID"
		class="galeria-imagens"
		:class="'imgs-' + imagesData.length"
	>
		<a
			v-for="(image, key) in imagesData"
			:key="key"
			:href="image.path"
			:data-pswp-width="image.width"
			:data-pswp-height="image.height"
			target="_blank"
			rel="noreferrer"
		>
			<img :src="image.path" alt="" />
		</a>
	</div>`,
}
