import MapaAtuacaoSVG from './MapaAtuacaoSVG'
import MapaAtuacaoLabels from './MapaAtuacaoLabels'
import MapaAtuacaoPin from './MapaAtuacaoPin'

const { ref, computed, watch } = Vue

export default {
	emits: ['click:pin'],

	props: {
		ativos: Array,
		atuacoes: Array,
		inicial: {
			type: Boolean,
			default: true,
		},
	},

	components: {
		MapaAtuacaoSVG,
		MapaAtuacaoPin,
		MapaAtuacaoLabels,
	},

	setup(props, ctx) {
		const refMapa = ref(null)
		const inicial = ref(props.inicial)

		watch(
			() => props.inicial,
			(v) => {
				inicial.value = v
			}
		)

		function handleClick(ativo) {
			inicial.value = false
			
			// const x = e.offsetX + 'px'
			// const y = e.offsetY + 'px'

			// addElementMapa(x, y);
			// addLabelsInMap(e, x, y);

			ctx.emit('click:pin', ativo.categoriaUniqueID)
			console.log(ativo.categoriaUniqueID)
		}

		return {
			refMapa,
			handleClick,
			inicial,
		}
	},

	template: html`
		<div class="mapa-wrapper" :class="{'inicial': inicial}">
			<MapaAtuacaoPin
				v-for="ativo in ativos"
				:key="ativo.id"
				:ativo="ativo"
				:icone="ativo.icone"
				@click="handleClick(ativo)"
			/>
			<MapaAtuacaoSVG />
			<!-- <MapaAtuacaoLabels v-if="inicial" /> -->
		</div>
	`,
}
