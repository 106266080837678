import CategoriasMapa from './CategoriasMapa'
import { state, open, close } from '../store/modalAtuacao.store'
import SimpleGallery from './SimpleGallery.js'
import { getDetalhes, detalhesCidade } from '../store/mapaAtuacao.store'

const { ref, watch, computed } = Vue

export default {
	// emits: ['click:categoria'],
	components: { CategoriasMapa, SimpleGallery },

	props: {
		listaCategoria: Array,
		atuacoes: Array,
	},

	setup(props) {
		const images = ref([])
		const categoriaClicked = ref({ id: null, categoriaUniqueID: null })

		// cidadeID
		const cidadeActive = computed(() => {
			if (props.atuacoes.length) {
				return props.atuacoes.reduce((acc, i) => {
					if (i.id === state.value.cidadeID) {
						acc = i
					}
					return acc
				})
			} else {
				return null
			}
		})

		const imagensAtivos = computed(() => {
			if (!detalhesCidade.value || !detalhesCidade.value.imagens) return []
			return detalhesCidade.value.imagens.reduce((acc, i) => {
				i.imagens.forEach((img) => acc.push(img))
				return acc
			}, [])
		})

		const imagensAtivoscategoriaClicked = computed(() => {
			if (!detalhesCidade.value || !detalhesCidade.value.imagens) return []

			return detalhesCidade.value.imagens
				.reduce((acc, i) => {
					i.imagens.forEach((img) => acc.push(img))
					return acc
				}, [])
				.filter((foto) => foto.categoria_id === categoriaClicked.value.id)
		})

		const loaderIMG = ref(true)
		watch(
			() => state.value.cidadeID,
			async (id) => {
				console.log('state.cidadeID', state.value)
				const categoriasAtivas = props.listaCategoria
					.filter((i) => i.active)
					.map((i) => i.id)
					.join(',')

				if (!id) {
					categoriaClicked.value = { id: null, categoriaUniqueID: null }
					detalhesCidade.value = { imagens: [] }
				} else {
					loaderIMG.value = true
					await getDetalhes(id, { categoria: categoriasAtivas })
					loaderIMG.value = false
				}
			}
		)

		watch(
			() => state.value.open,
			(v) => {
				if (v) {
					document.documentElement.classList.add('modal-open')
				} else {
					document.documentElement.classList.remove('modal-open')
				}
			}
		)

		return {
			state,
			open,
			close,
			images,
			cidadeActive,
			categoriaClicked,
			imagensAtivos,
			imagensAtivoscategoriaClicked,
			detalhesCidade,
			loaderIMG,
		}
	},

	template: html`
		<div
			class="modal-atuacao"
			:class="{'active': state.open }"
			v-if="cidadeActive"
		>
			<div class="modal--background" @click.stop.prevent.self="close">
				<div class="modal--container">
					<div class="modal--heading">
						<h4>{{cidadeActive.nome}}</h4>
						<button @click.stop.prevent="close">
							<svg>
								<use xlink:href="#icon_close"></use>
							</svg>
						</button>
					</div>

					<div class="modal--body">
						<div class="modal-left-col">
							<template
								v-for="categoria in cidadeActive.categorias"
								:key="categoria.id"
							>
								<div
									class="categoria-section"
									v-if="categoria.ativos.length && (categoriaClicked.categoriaUniqueID === null || categoriaClicked.categoriaUniqueID === categoria.uniqueID)"
								>
									<h5
										class="categoria-titulo name-cidade"
										@click.stop.prevent="categoriaClicked = { id: categoria.id, categoriaUniqueID: categoria.uniqueID }"
									>
										<div
											class="svg-container"
											v-if="categoriaClicked?.categoriaUniqueID !== null"
											@click.stop.prevent="categoriaClicked = { id: null, categoriaUniqueID: null }"
										>
											<svg class="icone_back_ativo">
												<use xlink:href="#arrow_back"></use>
											</svg>
										</div>
										<svg
											class="icone_categotria"
											style="--stroke: currentColor"
										>
											<use :xlink:href="'#icon_' + categoria.icone"></use>
										</svg>
										{{categoria.nome}}
									</h5>

									<ul v-if="categoria.ativos.length">
										<!-- 	v-show="categoriaClicked.id === null || categoriaClicked.id === ativo.id" -->
										<li v-for="ativo in categoria.ativos" :key="ativo.id">
											<!-- 			<span
												@click.stop.prevent="categoriaClicked = { id: ativo.id, categoriaUniqueID: categoria.uniqueID }"
												class="name-ativo"
											>
												<svg
													v-show="categoriaClicked?.id !== null"
													class="icone_back_ativo"
													@click.stop.prevent="categoriaClicked = { id: null, categoriaUniqueID: null }"
												>
													<use xlink:href="#arrow_back"></use>
												</svg>

												{{ativo.nome}}
											</span> -->
											<div class="ativo-wrapper">
												<!-- 	<template v-if="categoriaClicked?.id === ativo.id"> -->
												<div
													v-for="descricao in ativo.descricao"
													class="ativo-content"
												>
													{{descricao.content}}
												</div>
												<!-- 		</template> -->
											</div>
										</li>
									</ul>
								</div>
							</template>
						</div>
						<div class="modal-right-col">
							<!-- 		@click:categoria="(v) => $emit('click:categoria', v)" -->
							<CategoriasMapa
								:categorias="listaCategoria"
								class="pointer-events-none"
								noclose
								v-if="false"
							></CategoriasMapa>

							<!-- PHOTO SWIPER -->

							<SimpleGallery
								v-if="!loaderIMG"
								galleryID="my-test-gallery"
								:images="categoriaClicked.id ? imagensAtivoscategoriaClicked : imagensAtivos"
							/>
							<div v-else class="loader-container">
								<span class="loader-basic"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
}
